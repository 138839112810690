import React from "react";
import Modal from "react-modal";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { YouTubeURLParser } from "@iktakahiro/youtube-url-parser";
import { graphql } from "gatsby";

import Close from "../media/icons/close.svg";
import Play from "../media/icons/play.svg";
import Date from "../media/icons/date.svg";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Reference = (props) => {
  console.log(props);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isClient, setIsClient] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(0);
  const { slug, title, content, date, images } =
    props.pageContext.node.frontmatter;

  console.log({ images });
  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = (e) => {
    setSelectedItem(Number(e.target.dataset.index));
    setIsOpen(true);
  };

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Layout path={props.path}>
      <SEO title={`Reference - ${slug}`} />
      <div className="Reference">
        <h1 className="Reference__title">{title}</h1>
        <span className="Reference__date">
          <Date className="Reference__date-svg" />
          {date}
        </span>
        <div className="Reference__container">
          <div
            className="Reference__content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <div className="Reference__gallery">
            <Modal
              isOpen={isOpen}
              onRequestClose={closeModal}
              className="Modal"
              overlayClassName="Overlay"
            >
              <button
                type="button"
                className="Modal__close"
                onClick={closeModal}
              >
                <Close />
              </button>
              <Carousel selectedItem={selectedItem} showThumbs={true}>
                {isClient &&
                  images.map((item, index) => {
                    console.log(item, selectedItem);
                    if (item.yt_url) {
                      const parser = new YouTubeURLParser(item.yt_url);
                      if (!parser.isValid()) return null;
                      const src = `https://www.youtube.com/embed/${parser.getId()}`;

                      return (
                        <div key={index} style={{ height: "100%" }}>
                          <iframe
                            src={src}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          />
                        </div>
                      );
                    }
                    return (
                      <div key={index}>
                        <img src={item.image.publicURL} />
                      </div>
                    );
                  })}
              </Carousel>
            </Modal>

            <div className="Reference__grid">
              {isClient &&
                images.map((item, index) => {
                  if (item.yt_url) {
                    const parser = new YouTubeURLParser(item.yt_url);
                    if (!parser.isValid()) return null;

                    const src = parser.getThumbnailURL();
                    return (
                      <button
                        type="button"
                        key={index}
                        data-index={index}
                        className="Reference__grid-button"
                        onClick={openModal}
                      >
                        <img className="Reference__grid-item" src={src} />
                        <Play className="Reference__grid-play" />
                      </button>
                    );
                  }
                  return (
                    <button
                      type="button"
                      key={index}
                      data-index={index}
                      className="Reference__grid-button"
                      onClick={openModal}
                    >
                      <GatsbyImage
                        className="Reference__grid-item"
                        image={getImage(item.image)}
                      />
                    </button>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

/*
export const query = graphql`
    query($slug: String!) {
      wordpressWpReference(slug: { eq: $slug } ) {
        content
        title
        slug
        acf {
          galerie {
            source_url
          }
          date
          video {
            yt_url
          }
        }
      }
    }
  `;
*/

/*
export const query = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
*/

export default Reference;
